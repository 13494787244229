import { orderBy } from 'lodash';

import { STDT_REIMBURSEMENT, STTG_CTMS } from '../../../../../constants/study/studyTypes';

export function resolveRootKey(currentRoute) {
  let rootKey = currentRoute?.parent?.name;
  if (rootKey === 'Investigator Review' || rootKey === 'SM Review') {
    rootKey = 'Patients';
  }
  return rootKey;
}

export function reshapeAndOrderSourceData(data) {
  /*
    Reshape data so that all instances of source 'Other' will now include their `type` in the source value
    i.e. change:
    {id: 'a3-b1-3c', source: 'Other', type: 'Social Media'}
    to:
    {id: 'a3-b1-3c', source: 'Other - Social Media', type: 'Social Media'}
  */
  const dataReshaped = data?.map(d => (d?.source === 'Other' ? { ...d, source: `${d?.source} - ${d?.type}` } : d));

  return orderBy(
    dataReshaped.filter(source => source.type !== 'API'),
    d => d?.source?.toLowerCase()
  );
}

export function isCtmsReimbursementAndReimbursementEmailOptIn(study) {
  return (
    study &&
    study.type === STTG_CTMS &&
    study.types.some(e => e === STDT_REIMBURSEMENT) &&
    study.reimbursementEmailOptIn
  );
}

export function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
