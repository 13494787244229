import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

export const INVOICE_FILTERS = 'INVOICE_FILTERS';
export const INVOICE_SELECTED_SEARCH_RESULT = 'INVOICE_SELECTED_SEARCH_RESULT';
export const TABLE_CHECKBOX = GRID_CHECKBOX_SELECTION_COL_DEF.field;
export const ACTIONS_BUTTON = 'actions';

export const OPEN = 'Open';
export const PAST_DUE = 'Past Due';
export const PAID = 'Paid';
export const PARTIAL = 'Partial';
export const COMPLETE = 'Complete';

export const PARENT = 'PARENT';
export const WITHHOLDING = 'WITHHOLDING';
export const CREDIT_MEMO = 'CREDIT_MEMO';
export const DEBIT_MEMO = 'DEBIT_MEMO';

export const COLUMNS_HIDDEN_BY_DEFAULT = {
  studyName: false,
  pcnName: false,
  siteName: false,
  clientName: false,
  eventDate: false,
  daysOpen: false,
  budgetEventType: false,
  epochName: false,
  expenseVariableComment: false,
  encounterName: false,
  itemGroupName: false,
  investigator: false,
  patientId: false,
  subjectId: false,
  adjustmentReason: false,
  siteNumber: false,
  nonInvoiceable: false
};
