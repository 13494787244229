export const SITE_PAYMENTS_COLUMNS_HIDDEN_BY_DEFAULT = {
  studyName: false,
  pcn: false,
  siteName: false,
  siteNumber: false,
  clientName: false,
  budgetEventType: false,
  epochName: false,
  encounterName: false,
  itemGroupName: false,
  eventDate: false,
  invoiceNumber: false,
  reconcileDepositNumber: false,
  reconcileDaysOpen: false,
  reconcileTotalAmountPaid: false,
  reconcilePostedDate: false,
  reconcileVariance: false,
  investigator: false,
  patientId: false,
  subjectId: false,
  sitePaymentDate: false,
  adjustmentMemo: false,
  currentPatientStatus: false,
  sitePaymentComment: false,
  siteBillExportDate: false,
  nonInvoiceable: false
};

export const SiteApprovalStatus = {
  OPEN: 'Open',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  READY_FOR_APPROVAL: 'Ready for Approval'
};

export const SitePaymentStatus = {
  OPEN: 'Open',
  PENDING: 'Pending',
  PAID: 'Paid'
};

export const OPEN = 'OPEN';
export const PENDING = 'PENDING';
export const PAID = 'PAID';

export const PARENT = 'PARENT';
export const WITHHOLDING = 'WITHHOLDING';
export const SITE_CREDIT = 'SITE_CREDIT';
export const SITE_CREDIT_WH = 'SITE_CREDIT_WH';
export const SITE_DEBIT = 'SITE_DEBIT';
export const SITE_DEBIT_WH = 'SITE_DEBIT_WH';
