import React, { useCallback, useEffect, useState } from 'react';
import { unionBy } from 'lodash/array';
import { cloneDeep, isArray, isEmpty } from 'lodash/lang';
import moment from 'moment';

import { PatientInfoApi, PatientSourceApi, TaskApi } from '../../../../../api';
import InformedConsentApi from '../../../../../api/patient/InformedConsentApi';
import PatientInfoPageApi from '../../../../../api/patient/PatientInfoPageApi';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import openKeepIsHidden from '../../../../root/Container/Layout/Tasks/openKeepIsHidden';
import { useCurrentRoute } from '../../../../root/router';

import { reshapeAndOrderSourceData } from './patientInfoService';

export const PatientInfoContext = React.createContext(null);

export function PatientInfoProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const [studyHistoryLoading, setStudyHistoryLoading] = useState(true);
  const [studyHistory, setStudyHistory] = useState(null);
  const [forms, setForms] = useState([]);
  const [patientInfoLoading, setPatientInfoLoading] = useState(true);
  const [reimbursementEmailNotificationLoading, setReimbursementEmailNotificationLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [currentStudy, setCurrentStudy] = useState(null);
  const [uniqueSites, setUniqueSites] = useState(null);
  const [sourcesList, setSourcesList] = useState(null);
  const [activePatientEnablementCollapseKey, setActivePatientEnablementCollapseKey] = useState(-1);

  const updatePatientRelatedStudies = useCallback(
    function() {
      let canceled = false;
      setStudyHistoryLoading(true);
      PatientInfoApi.getPatientRelatedStudies(patientId).then(function(res) {
        if (canceled) {
          return;
        }
        setStudyHistory(res.data.items);
        setStudyHistoryLoading(false);
      });
      return function() {
        canceled = true;
      };
    },
    [patientId]
  );

  const updatePatientInfo = useCallback(
    function() {
      let canceled = false;
      setPatientInfoLoading(true);
      PatientInfoApi.getPatientInfo(patientId).then(function(res) {
        if (canceled) {
          return;
        }
        setPatientInfo(convertDobStringToMoment(res.data));
        setPatientInfoLoading(false);
      });
      return function() {
        canceled = true;
      };
    },
    [patientId]
  );

  const setSubjectIdBySsuPatientId = useCallback(function(ssuPatientId, subjectId) {
    setStudyHistory(prevState => {
      const cloned = cloneDeep(prevState),
        study = cloned.find(study => study.ssuPatientId === ssuPatientId);
      study.patientSubjectId = subjectId;
      return cloned;
    });
  }, []);

  useEffect(function() {
    PatientSourceApi.getAllByTypeSource().then(({ data }) => {
      setSourcesList(reshapeAndOrderSourceData(data));
    });
  }, []);

  useEffect(
    function() {
      if (isArray(studyHistory)) {
        setCurrentStudy(studyHistory.find(study => study.ssuPatientId === ssuPatientId));
        setUniqueSites(
          unionBy(
            studyHistory.map(({ siteId, siteName }) => ({ siteId, siteName })),
            'siteId'
          )
        );
      }
    },
    [ssuPatientId, studyHistory]
  );

  const getNewAndCompletedIcfForms = useCallback(() => {
    InformedConsentApi.getNewAndCompletedForms(currentStudy.ssuId, ssuPatientId).then(({ data }) => {
      const completedIds = data.completedIcf?.filter(e => e.informedConsentFormId).map(e => e.informedConsentFormId);
      data.newIcf = data.newIcf.filter(e => !completedIds.includes(e.id));
      setForms(data);
    });
  }, [currentStudy?.ssuId, ssuPatientId]);

  useEffect(() => {
    !!ssuPatientId && !!currentStudy && getNewAndCompletedIcfForms();
  }, [currentStudy, ssuPatientId, getNewAndCompletedIcfForms]);

  useEffect(
    function() {
      const cancelUpdatePatientRelatedStudies = updatePatientRelatedStudies();
      const cancelUpdatePatientInfo = updatePatientInfo();
      return function() {
        cancelUpdatePatientRelatedStudies();
        cancelUpdatePatientInfo();
      };
    },
    [updatePatientRelatedStudies, updatePatientInfo]
  );

  const setPatientMedicalRecordNumberBySsuPatientId = useCallback(function(ssuPatientId, patientMedicalRecordNumber) {
    setStudyHistory(prevState => {
      const cloned = cloneDeep(prevState),
        study = cloned.find(study => study.ssuPatientId === ssuPatientId);
      study.medicalRecordNumber = patientMedicalRecordNumber;
      return cloned;
    });
  }, []);

  const setPatientSourceIdBySsuPatientId = useCallback(function(ssuPatientId, id) {
    setStudyHistory(prevState => {
      const cloned = cloneDeep(prevState),
        study = cloned.find(study => study.ssuPatientId === ssuPatientId);
      study.patientSourceId = id;
      return cloned;
    });
  }, []);

  const getPatientRelatedTasks = useCallback(
    function() {
      TaskApi.getTasksByPatientIdAndSSU(ssuPatientId, currentStudy?.ssuId).then(({ data }) => {
        if (!isEmpty(data)) {
          openKeepIsHidden(data[0].taskId, data[0].ssuId);
        }
      });
    },
    [currentStudy?.ssuId, ssuPatientId]
  );

  useEffect(() => {
    getPatientRelatedTasks();
  }, [getPatientRelatedTasks]);

  const onReimbursementEmailNotificationsSave = reimbursementEmailNotifications => {
    setReimbursementEmailNotificationLoading(true);
    PatientInfoPageApi.updatePatientReimbursementEmailNotifications(patientInfo.id, reimbursementEmailNotifications)
      .then(() => {
        setPatientInfo(state => {
          return { ...state, reimbursementEmailNotifications: reimbursementEmailNotifications };
        });
        setReimbursementEmailNotificationLoading(false);
      })
      .catch(() => {
        NotificationManager.error('Unable to save Reimbursement notifications');
        setReimbursementEmailNotificationLoading(false);
      });
  };

  return (
    <PatientInfoContext.Provider
      value={{
        studyHistoryLoading,
        patientInfoLoading,
        studyHistory,
        sourcesList,
        patientInfo,
        currentStudy,
        updatePatientRelatedStudies,
        updatePatientInfo,
        setSubjectIdBySsuPatientId,
        uniqueSites,
        forms,
        setPatientMedicalRecordNumberBySsuPatientId,
        setPatientSourceIdBySsuPatientId,
        getNewAndCompletedIcfForms,
        onReimbursementEmailNotificationsSave,
        activePatientEnablementCollapseKey,
        setActivePatientEnablementCollapseKey,
        reimbursementEmailNotificationLoading
      }}
    >
      {children}
    </PatientInfoContext.Provider>
  );
}

function convertDobStringToMoment(patientInfo) {
  patientInfo.dob = patientInfo.dob && moment(patientInfo.dob);
  return patientInfo;
}
