import React, { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';
import Collapse from 'rc-collapse';

import { LoadingHandler } from '../../../../../../common/elements/Loader/LoadingHandler';
import { PatientInfoContext } from '../PatientInfoContext';
import { validateEmail } from '../patientInfoService';

import './PatientEnablementSection.scss';

export default function PatientEnablementSection() {
  const {
    patientInfo,
    onReimbursementEmailNotificationsSave,
    activePatientEnablementCollapseKey,
    setActivePatientEnablementCollapseKey,
    reimbursementEmailNotificationLoading
  } = useContext(PatientInfoContext);

  const [currentReimbursementEmailNotificationsState, setCurrentReimbursementEmailNotificationsState] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const dataLoaded = !reimbursementEmailNotificationLoading;
  const reimbursementEmailNotifications = patientInfo?.reimbursementEmailNotifications;

  useEffect(() => {
    setCurrentReimbursementEmailNotificationsState(reimbursementEmailNotifications);
  }, [reimbursementEmailNotifications]);

  return (
    <React.Fragment>
      {activePatientEnablementCollapseKey && (
        <Collapse
          activeKey={activePatientEnablementCollapseKey}
          onChange={key => setActivePatientEnablementCollapseKey(key)}
          expandIcon={({ isActive }) => (
            <span className="rc-collapse-header-expand-icon material-icons">
              {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
            </span>
          )}
          className="eds-patient-enablement-section"
        >
          <Collapse.Panel
            header={
              <React.Fragment>
                <div className="rc-collapse-header-title">PATIENT ENABLEMENT</div>
              </React.Fragment>
            }
            key="1"
          >
            <LoadingHandler complete={dataLoaded} dark>
              {() => {
                return (
                  <React.Fragment>
                    <section>
                      <Box sx={{ flexGrow: 1 }} component="form">
                        <Grid container spacing={2} alignItems="center">
                          <Grid xs={6}>
                            <FormControl
                              error={showErrorMessage}
                              required
                              component="fieldset"
                              sx={{ m: 1 }}
                              variant="standard"
                            >
                              <FormControlLabel
                                value="top"
                                control={
                                  <Checkbox
                                    checked={currentReimbursementEmailNotificationsState}
                                    onChange={({ target }) =>
                                      setCurrentReimbursementEmailNotificationsState(target.checked)
                                    }
                                  />
                                }
                                label="Reimbursement Email Notification"
                              />
                              {showErrorMessage && (
                                <FormHelperText>
                                  Email can’t be empty when reimbursement notifications are enabled
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid xs={6}>
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                              <IconButton
                                aria-label="save"
                                disabled={
                                  currentReimbursementEmailNotificationsState === reimbursementEmailNotifications
                                }
                                color="primary"
                                onClick={() => {
                                  if (
                                    patientInfo &&
                                    patientInfo.email &&
                                    patientInfo.email.length !== 0 &&
                                    validateEmail(patientInfo.email)
                                  ) {
                                    setShowErrorMessage(false);
                                    onReimbursementEmailNotificationsSave(currentReimbursementEmailNotificationsState);
                                  } else {
                                    setShowErrorMessage(true);
                                  }
                                }}
                                title="Save"
                              >
                                <SaveIcon />
                              </IconButton>
                              <IconButton
                                aria-label="discard"
                                disabled={
                                  currentReimbursementEmailNotificationsState === reimbursementEmailNotifications
                                }
                                color="primary"
                                onClick={() => {
                                  setShowErrorMessage(false);
                                  setCurrentReimbursementEmailNotificationsState(reimbursementEmailNotifications);
                                }}
                                title="Discard changes"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </section>
                  </React.Fragment>
                );
              }}
            </LoadingHandler>
          </Collapse.Panel>
        </Collapse>
      )}
    </React.Fragment>
  );
}
