import React from 'react';
// Browser tweaks
import { isEdge, isFirefox, isIE } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';

// Stylesheets
import './images/icons/tick-arrow.png'; // Webpack required
import './images/icons/plus-icon.png'; // Webpack required
// TODO Workaround. Polyfill for ES6 Symbol https://www.npmjs.com/package/es6-symbol
import 'es6-symbol';

import VersionApi from './api/patient/VersionApi';
import { LOGOUT_TIME } from './components/root/Container/Container';
import WithBouncerOrAmplify from './components/root/Container/WithBouncerOrAmplify';
import cognitoConfig from './constants/cognitoConfig';
import { LAST_ACTION } from './constants/localStorageConstants';
import { handleLogout } from './services/auth';
import { update } from './store/appInfo/actions';
import muiTheme from './theme/mui-theme';
import store from './store';

import './index.scss';

if (isIE || isEdge || isFirefox) {
  require('./ie.css');
}

LicenseInfo.setLicenseKey(
  'bda3a72db5c663c68a6923f2759694d9Tz05NTY4OCxFPTE3NTQ1Nzc2MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

function renderAll(theme) {
  ReactDOM.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={muiTheme}>
          <WithBouncerOrAmplify theme={theme} />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>,
    document.getElementById('root')
  );
}

async function begin() {
  clearLastActionCounter();
  await cognitoConfig();
  const appInfo = await loadAppInfo();
  const theme = generateAppTheme(appInfo.displayAppVersion);
  renderAll(theme);
  //register();
}

async function loadAppInfo() {
  return VersionApi.getVersion().then(({ data }) => {
    console.log('Application version:');
    console.log(data);
    store.dispatch(update(data));
    return data;
  });
}

export function generateAppTheme(displayAppVersion) {
  const trainingOrSandboxEnvironmentMainColor = '#015592',
    trainingOrSandboxEnvironmentExtraColor1 = '#1269A9',
    trainingOrSandboxEnvironmentExtraColor2 = '#044270';
  const stagingEnvironmentMainColor = '#019155',
    stagingEnvironmentExtraColor1 = '#12A969',
    stagingEnvironmentExtraColor2 = '#047042';
  if (displayAppVersion?.includes('TRN') || displayAppVersion?.includes('SND')) {
    return {
      '--environment-main-color': trainingOrSandboxEnvironmentMainColor,
      '--environment-extra-color-1': trainingOrSandboxEnvironmentExtraColor1,
      '--environment-extra-color-2': trainingOrSandboxEnvironmentExtraColor2
    };
  }
  if (displayAppVersion?.includes('STAGE')) {
    return {
      '--environment-main-color': stagingEnvironmentMainColor,
      '--environment-extra-color-1': stagingEnvironmentExtraColor1,
      '--environment-extra-color-2': stagingEnvironmentExtraColor2
    };
  }
}

// clear the lastAction counter (UNIX timestamp) if it's been
// over an hour since last updated, meaning the user would already
// be logged out; this will prevent having to log in twice
async function clearLastActionCounter() {
  const localStorageLastAction = localStorage.getItem(LAST_ACTION);
  if (localStorageLastAction) {
    const now = Date.now();
    const lastAction = JSON.parse(localStorageLastAction);
    const timeLeft = lastAction + LOGOUT_TIME;
    const diff = timeLeft - now;
    if (diff < 0) {
      await handleLogout(false);
    }
  }
}

begin();
