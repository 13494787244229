import axios from 'axios';
import moment, { isMoment } from 'moment';

import { DATE_FORMAT } from '../../components/pages/ModeReports/modeReportsServices';
import { TRST_FLIGHT } from '../../components/pages/patient-source/Patients/PatientInfo/EncountersSection/TravelSection/travelConstants';

const ROOT = 'api/v1/travels';

const PatientTravelApi = {
  getTravelPreferences(patientId) {
    return axios.post(`${ROOT}/patients/${patientId}/preferences`, {}, { hideLoader: true }).then(function({ data }) {
      return {
        data: {
          preferredPickupAddress: mapAddressResponse(data.pickupAddress),
          airlinePreferences: mapAirlinePreferencesResponse(data.airline),
          hotelPreferences: mapHotelPreferencesResponse(data.hotel)
        }
      };
    });
    function mapAirlinePreferencesResponse(airline) {
      return {
        preferredTravelTime: airline?.travelTime || [],
        preferredAirline: airline?.airline || '',
        frequentFlightNumber: airline?.frequentFlightNumber || '',
        seatingPreference: airline?.seating || '',
        preferredAirport: airline?.airport || '',
        specialAccommodation: airline?.specialAccommodation || ''
      };
    }
    function mapHotelPreferencesResponse(hotel) {
      return {
        preferredHotelChain: hotel?.hotelChain || '',
        specialAccommodation: hotel?.specialAccommodation || ''
      };
    }
  },
  savePreferredPickupAddress(patientId, pickupAddress) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/pickup-address`, mapAddressRequest(pickupAddress), {
      hideLoader: false
    });
  },
  saveAirlinePreferences(patientId, preferences) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/airlines`, mapRequest(preferences), {
      hideLoader: false
    });
    function mapRequest(preferences) {
      return {
        airline: preferences.preferredAirline,
        frequentFlightNumber: preferences.frequentFlightNumber,
        airport: preferences.preferredAirport,
        specialAccommodation: preferences.specialAccommodation,
        seating: preferences.seatingPreference || null,
        travelTime: preferences.preferredTravelTime
      };
    }
  },
  saveHotelPreferences(patientId, preferences) {
    return axios.put(`${ROOT}/patients/${patientId}/preferences/hotels`, mapRequest(preferences), {
      hideLoader: false
    });
    function mapRequest(preferences) {
      return {
        hotelChain: preferences.preferredHotelChain,
        specialAccommodation: preferences.specialAccommodation
      };
    }
  },
  getPatientTravelRequests(ssuPatientId) {
    return axios.get(`${ROOT}/ssu-patients/${ssuPatientId}`, {
      hideLoader: false
    });
  },
  getPatientTravelRequest(ssuPatientId, travelRequestId) {
    return axios
      .get(`${ROOT}/ssu-patients/${ssuPatientId}/travel-requests/${travelRequestId}`, {
        hideLoader: false
      })
      .then(function({ data }) {
        return mapResponse(data);
      });

    function mapResponse(response) {
      const obj = {
        id: response.id,
        status: response.status,
        visitDate: moment(response.clientVisitStartTime)
      };
      if (response.clientVisitStartTime) {
        obj.visitStartTime = prepareTime(moment(), moment(response.clientVisitStartTime));
      }
      if (response.clientVisitEndTime) {
        obj.visitEndTime = prepareTime(moment(), moment(response.clientVisitEndTime));
      }
      if (response.requestedServices) {
        obj.requestedServices = response.requestedServices;
      }
      if (response.departureDate) {
        obj.departureDate = moment(response.departureDate).startOf('day');
      }
      if (response.returnDate) {
        obj.returnDate = moment(response.returnDate).startOf('day');
      }
      if (response.departureAirport) {
        obj.preferredDepartureAirport = response.departureAirport;
      }
      if (response.travelTime) {
        obj.preferredTravelTime = response.travelTime;
      }
      if (response.comment) {
        obj.comments = response.comment;
      }
      if (response.pickUpAddress) {
        obj.pickupAddress = mapAddressResponse(response.pickUpAddress);
        obj.pickupAddressComments = response.pickUpAddress?.comment;
      }
      if (response.dropOffAddress) {
        obj.dropOffAddress = mapAddressResponse(response.dropOffAddress);
        obj.dropOffAddressComments = response.dropOffAddress?.comment;
      }
      return obj;
    }
  },
  createPatientTravelRequest(ssuPatientId, request) {
    const r = mapTravelRequestRequest(request);
    return axios.post(`${ROOT}/ssu-patients/${ssuPatientId}`, r, {
      hideLoader: false
    });
  },
  updatePatientTravelRequest(ssuPatientId, travelRequestId, request) {
    return axios.put(
      `${ROOT}/ssu-patients/${ssuPatientId}/travel-requests/${travelRequestId}`,
      mapTravelRequestRequest(request),
      {
        hideLoader: false
      }
    );
  },
  updateStatus(ssuPatientId, travelRequestId, status) {
    return axios.put(
      `${ROOT}/ssu-patients/${ssuPatientId}/travel-requests/${travelRequestId}/statuses/${status}`,
      {},
      {
        hideLoader: false
      }
    );
  },
  getEncounter(ssuPatientId, encounterLookUpId) {
    return axios.get(`${ROOT}/ssu-patients/${ssuPatientId}/encounters/${encounterLookUpId}`, {
      hideLoader: false
    });
  },
  getSituationalEncounters(ssuPatientId) {
    return axios.get(`${ROOT}/ssu-patients/${ssuPatientId}/situational-encounters`, {
      hideLoader: false
    });
  }
};

export default PatientTravelApi;

function mapTravelRequestRequest(request) {
  return {
    handlerName: request.encounterInfo?.handlerName,
    protocolEncounterKey: request.encounterInfo?.protocolEncounterKey,
    sitePatientEncounterId: request.encounterInfo?.sitePatientEncounterId,
    situationalEncounterId: request.encounterInfo?.situationalEncounterId,
    requestedServices: request.requestedServices,
    visitStartTime: prepareTime(request.visitDate, request.visitStartTime),
    visitEndTime: prepareTime(request.visitDate, request.visitEndTime),
    preferredDateTime: request.requestedServices.includes(TRST_FLIGHT)
      ? {
          departureDate: isMoment(request.departureDate) ? moment(request.departureDate).format(DATE_FORMAT) : null,
          returnDate: isMoment(request.returnDate) ? moment(request.returnDate).format(DATE_FORMAT) : null,
          departureAirport: request.preferredDepartureAirport,
          travelTime: request.preferredTravelTime
        }
      : null,
    preferredPickUpAddress: mapAddressRequest(request.pickupAddress, request.pickupAddressComments),
    preferredDropOffAddress: mapAddressRequest(request.dropOffAddress, request.dropOffAddressComments),
    comment: request.comments
  };
}

function mapAddressResponse(address) {
  return {
    address1: address.address,
    valid: address.valid,
    address2: address.address2,
    city: address.city,
    regionId: address.region,
    zipCode: address.zip,
    countryId: address.country,
    type: address.otherAddress ? 'OTHER' : 'PATIENT_HOME_ADDRESS'
  };
}

function mapAddressRequest(address, comment) {
  return {
    address: address.address1,
    valid: address.valid,
    address2: address.address2,
    city: address.city,
    region: address.regionId,
    zip: address.zipCode,
    country: address.countryId,
    isOtherAddress: address.type === 'OTHER',
    comment
  };
}

function prepareTime(date, time) {
  if (!time?.isValid()) {
    return null;
  }
  time.set({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('date')
  });
  return time;
}
